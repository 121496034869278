<template>
  <div>
    <vx-card>
      <!-- TABLE ACTION ROW -->
      <div class="flex justify-between items-center">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4 mt-6"
          v-model="searchQuery"
          placeholder="Buscar área"
        />

        <vs-select class="" label="Negociación:" v-model="negotiation">
          <vs-select-item :value="null" text="Todos" />
          <vs-select-item
            :key="index"
            :value="negotiation.id"
            :text="negotiation.name"
            v-for="(negotiation, index) in negotiations"
          />
        </vs-select>
        <div class="mt-6 ml-3">
          <vs-button
            :to="{ name: 'configuracion-crear-tarifas-por-area' }"
            class="mb-4 md:mb-0"
            >Crear</vs-button
          >
        </div>
      </div>
      <section v-if="!areaTariffsLoading && !loading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="areaTariffs"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </section>
      <section v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </section>
    </vx-card>
  </div>
</template>

<script>
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import { useAgGrid } from '@/composable/useAgGrid.js';
import { AREA_TARIFFS, ALL_NEGOTIATIONS } from '@/graphql/queries.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import CellRendererTarifasPorAreaDelete from './cell-renderer/CellRendererTarifasPorAreaDelete.vue';
import CellRendererTarifasPorAreaEdit from './cell-renderer/CellRendererTarifasPorAreaEdit.vue';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererTarifasPorAreaEdit,
    CellRendererPercentAmount,
    CellRendererTarifasPorAreaDelete,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 100,
          filter: true,
        },
        {
          headerName: 'Editar',
          field: 'editar',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererTarifasPorAreaEdit',
        },
        {
          headerName: 'Nombre',
          field: 'name',
          width: 400,
          filter: true,
        },
        {
          headerName: 'Precio',
          field: 'price',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Negociación',
          field: 'negotiation.name',
          filter: true,
        },
        {
          headerName: 'Ciudad',
          field: 'city.name',
          filter: true,
        },
        {
          headerName: 'Tipo de vehículo',
          field: 'vehicleType.alias',
          filter: true,
        },
        {
          headerName: '% Ida y vuelta',
          field: 'round_trip_percent',
          filter: true,
          cellRendererFramework: 'CellRendererPercentAmount',
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      negotiation: null,
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');

    const negotiation = computed(() => state.negotiation);

    const { result: areaTariffsResult, loading: areaTariffsLoading } = useQuery(
      AREA_TARIFFS,
      {
        page: currentPage,
        first: first,
        search: searchQuery,
        negotiation: negotiation,
      }
    );

    watch(areaTariffsLoading, (value) => {
      state.loading = value;
    });

    const areaTariffs = useResult(
      areaTariffsResult,
      [],
      (data) => data.areaTariffs.data
    );

    const { result: negotiationsResult, loading: negotiationsLoading } =
      useQuery(ALL_NEGOTIATIONS);
    const negotiations = useResult(
      negotiationsResult,
      [],
      (data) => data.allNegotiations
    );
    watch(negotiationsLoading, (value) => {
      state.loading = value;
    });

    const paginatorInfo = useResult(areaTariffsResult, [], (data) => ({
      total: data.areaTariffs.paginatorInfo.total,
      perPage: data.areaTariffs.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { state: agGridState } = useAgGrid({ root });

    const confirmDelete = ({ areaTariff }) => {
      console.log(areaTariff);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar Eliminación`,
        text: `La alarma con la referencia será eliminada.`,
        accept: () => null,
        acceptText: 'Eliminar',
      });
    };

    return {
      ...toRefs(state),
      agGridState,
      totalPages,
      currentPage,
      areaTariffs,
      searchQuery,
      confirmDelete,
      areaTariffsLoading,
      negotiationsLoading,
      negotiations,
    };
  },
});
</script>
