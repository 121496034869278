<template>
  <div>
    <feather-icon
      icon="TrashIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current"
      @click="onDelete"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererTarifasPorAreaDelete',
  methods: {
    onDelete() {
      console.log('😄', this.params);
      this.params.confirmDelete({
        areaTariff: this.params.data,
      });
    },
  },
};
</script>
