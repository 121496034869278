<template>
  <div>
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererTarifasPorAreaEdit',
  methods: {
    editRecord() {
      this.$gates.hasPermission('edit_area_tariffs') &&
        this.$router
          .push('/configuracion/tarifas-por-area/editar/' + this.params.data.id)
          .catch(() => {});
    },
  },
};
</script>
